import { createContext, useContext, useState } from 'react';
import { COMMON_MESSAGES } from 'src/utils/Constants';
import AppSnackbar from 'src/context/Snackbar/AppSnackbar';

export const SnackBarContext = createContext();

export const SNACKBAR_TYPES = {
  error: 'error',
  success: 'success',
};

export function SnackBarProvider({ children }) {
  const initialValues = {
    message: '',
    open: false,
    type: SNACKBAR_TYPES.error,
  };
  const [snackbar, setSnackbar] = useState(initialValues);

  const showSuccessSnackbar = (message = COMMON_MESSAGES.success) =>
    setSnackbar({ open: true, type: SNACKBAR_TYPES.success, message });

  const showErrorSnackbar = (message = COMMON_MESSAGES.error) =>
    setSnackbar({ open: true, type: SNACKBAR_TYPES.error, message });

  const addSnackbar = ({ open = true, type, message }) =>
    setSnackbar({ open, type, message });

  const value = { showErrorSnackbar, showSuccessSnackbar, addSnackbar };

  return (
    <SnackBarContext.Provider value={value}>
      {children}
      <AppSnackbar snackbar={snackbar} setSnackbar={setSnackbar} />
    </SnackBarContext.Provider>
  );
}

// Custom Hooks
export const useSnackBars = () => useContext(SnackBarContext);
