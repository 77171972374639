import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import PropTypes from 'prop-types';
import Head from 'next/head';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'src/theme';
import { AbilityProvider } from 'src/context/ability/AbilityContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Features } from 'src/context/Features/Features';
import { FeaturesProvider } from 'src/context/Features/FeaturesContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { SnackBarProvider } from 'src/context/Snackbar/SnackBarContext';
import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';
import ErrorBoundary from 'src/components/ErrorBoundary';

const queryClient = new QueryClient();

//desactivo Sentry para el entorno de desarrollo
if (process.env.NODE_ENV !== 'development') {
  const environment = process.env.NODE_ENV;
  const dns = process.env.NEXT_PUBLIC_APP_SENTRY_DNS;
  //uso el hash del commit como release de sentry para linkear los sourcemaps
  const release = process.env.NEXT_PUBLIC_COMMIT; //(viene del dockerfile y gitlab)
  Sentry.init({
    dsn: dns,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: environment,
    release: release,
  });
}

export default function MyApp(props) {
  const { Component, pageProps } = props;
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>Siguiendo Activos</title>
      </Head>
      <ErrorBoundary>
        <AbilityProvider>
          <FeaturesProvider value={Features}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-left"
                />
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <SnackBarProvider>
                    {getLayout(<Component {...pageProps} />)}
                  </SnackBarProvider>
                </ThemeProvider>
              </QueryClientProvider>
            </LocalizationProvider>
          </FeaturesProvider>
        </AbilityProvider>
      </ErrorBoundary>
    </>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};
