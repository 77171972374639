import axios from 'axios';
import { ErrorCodes } from 'src/utils/Constants';
import AuthService from 'src/services/AuthService';

const axiosInstanceWithInterceptors = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

// Add a response interceptor
axiosInstanceWithInterceptors.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);
const responseErrorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    // console.log('responseErrorHandler', error);
    if (error.response) {
      const { status, data } = error.response;
      const { detail } = data;
      // The request was made and the server responded with a status code
      if (status === 401) {
        if (detail === ErrorCodes.TOKEN_EXPIRED) {
          return AuthService.refreshToken().then((result) => {
            const { data } = result;
            AuthService.saveAuthToken(data);
            error.config.headers['Authorization'] =
              AuthService.getAuthorizationHeader();
            return axiosInstanceWithInterceptors.request(error.config);
          });
        }
        if (detail === ErrorCodes.TOKEN_INVALID) {
          console.log('ErrorCodes.TOKEN_INVALID: Recargando el sitio...');
          window.location.reload(); //TODO: se podría abrir modal de login acá o mostrar un error específico
        }
        if (detail === ErrorCodes.LOGOUT) {
          AuthService.logout();
          window.location.reload();
        }
      }
      if (status === 403) {
        console.error('permisos insuficientes');
      }
      if (status === 404) {
        console.error('endpoint API no encontrado');
      }
      if (status === 409) {
        console.error('codigo 409 (ya existe registro?)');
      }
      if (status > 500) {
        console.error('API caida');
      }
      if (detail) {
        return Promise.reject(detail);
      }
    } else if (error.request) {
      // no hay internet o no se pudo conectar al backend
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      console.error(
        'Error de conexión asegurate de tener internet',
        error.request
      );
      // return Promise.reject(ErrorCodes.NETWORK_ERROR);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error del request revisar el front', error);
    }
  }
  return Promise.reject({ ...error });
};
const responseSuccessHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // console.log("responseSuccessHandler", response);
    // TODO: Finalizar loading si es que seteo uno
  }
  return response;
  // return response.headers['content-type'] === 'application/json'
  //   ? response
  //   : Promise.reject(response);
};
const isHandlerEnabled = (config = {}) => {
  // eslint-disable-next-line no-prototype-builtins
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled
    ? false
    : true;
};

// Add a request interceptor
axiosInstanceWithInterceptors.interceptors.request.use(
  function (config) {
    //TODO: chequear si se necesita el token que esté seteado, cc abrir el modal de login
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosInstanceWithInterceptors;
