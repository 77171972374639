import * as qs from 'qs';
import decode from 'jwt-decode';
import axiosInstanceWithInterceptors from 'src/services/InterceptorsForAxiosRequests';
import { apiRoute, routeLayout } from 'src/paths';
import {
  BORoles,
  localStorageKeys,
  PERMISSION_KEYS,
} from 'src/utils/Constants';

const { AUTH_TOKEN, AUTH_USER } = localStorageKeys;

export const storage =
  typeof window !== 'undefined' ? window?.localStorage : {};

class AuthService {
  //ingreso
  login = (credentials) => this.getToken(credentials);

  //blanquear-clave
  resetPassword(username) {
    return axiosInstanceWithInterceptors.post(
      apiRoute.forgotAuthPassword,
      username
    );
  }

  getStatus() {
    return axiosInstanceWithInterceptors.get(apiRoute.status_control);
  }

  getToken = (credentials) => {
    const formData = qs.stringify({
      username: credentials.username,
      password: credentials.password,
    });
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    };
    return axiosInstanceWithInterceptors.post(
      apiRoute.authToken + '?captcha=' + credentials.captcha,
      formData,
      headers
    );
  };

  saveTokenInLocalStorage = (response) => {
    const data = response?.data;
    const accessToken = data?.access_token;
    if (!accessToken) return false;
    try {
      storage.setItem(AUTH_TOKEN, JSON.stringify(data));
      return true;
      // this.getCurrentUserAndSaveInLocalStorage();
    } catch (e) {
      return false;
    }
  };

  getUserInfo() {
    try {
      const authUser = JSON.parse(storage.getItem(AUTH_USER));
      return authUser ? authUser : '';
    } catch (e) {
      return false;
    }
  }

  getUsername() {
    const { username } = this.getUserInfo();
    return username ? username : '';
  }

  getUserId() {
    const { id_user } = this.getUserInfo();
    return id_user ? id_user : '';
  }

  canAccessToApplication() {
    return this.hasPermission(PERMISSION_KEYS.APPLICATION_ACCESS);
  }

  canAccessToBackoffice() {
    return this.hasPermission(PERMISSION_KEYS.BACKOFFICE_ACCESS);
  }

  canAccessFleets() {
    return this.hasPermission(PERMISSION_KEYS.FLEET_LIST);
  }

  canCreateAsset() {
    return this.hasPermission(PERMISSION_KEYS.ASSET_CREATE);
  }

  canEditAsset() {
    return this.hasPermission(PERMISSION_KEYS.ASSET_EDIT);
  }

  canGetAssetExcel() {
    return this.hasPermission(PERMISSION_KEYS.ASSET_GET_EXCEL);
  }

  canPostAssetExcel() {
    return this.hasPermission(PERMISSION_KEYS.ASSET_POST_EXCEL);
  }

  canCreateFleet() {
    return this.hasPermission(PERMISSION_KEYS.FLEET_CREATE);
  }

  canEditFleet() {
    return this.hasPermission(PERMISSION_KEYS.FLEET_EDIT);
  }

  canDeleteFleet() {
    return this.hasPermission(PERMISSION_KEYS.FLEET_DELETE);
  }

  canGetFleetExcel() {
    return this.hasPermission(PERMISSION_KEYS.FLEET_GET_EXCEL);
  }

  canCreateDevice() {
    return this.hasPermission(PERMISSION_KEYS.DEVICES_CREATE);
  }

  canEditDevice() {
    return this.hasPermission(PERMISSION_KEYS.DEVICES_EDIT);
  }

  canGetDeviceExcel() {
    return this.hasPermission(PERMISSION_KEYS.DEVICES_GET_EXCEL);
  }

  canShowDeviceFullList() {
    return (
      this.hasPermission(PERMISSION_KEYS.DEVICES_LIST) &&
      !this.hasPermission(PERMISSION_KEYS.DEVICES_LIST_OWN_COMPANY)
    );
  }

  canModifyHimself() {
    return (
      this.hasPermission(PERMISSION_KEYS.USER_EDIT) &&
      !this.hasPermission(PERMISSION_KEYS.USER_CANNOT_MODIFY_HIMSELF)
    );
  }

  canEditSuperAdmin() {
    return (
      this.hasPermission(PERMISSION_KEYS.USER_EDIT) &&
      !this.hasPermission(PERMISSION_KEYS.USER_CANNOT_EDIT_SUPERADMIN)
    );
  }

  hasPermission(permission) {
    try {
      const { permissions } = this.getUserInfo();
      return permissions ? permissions.includes(permission) : false;
    } catch (e) {
      return false;
    }
  }

  isSuperAdmin() {
    return this.hasRole(BORoles.SUPER_ADMIN);
  }

  isAdmin() {
    return this.hasRole(BORoles.ADMIN_MANAGER);
  }

  isFleetManager() {
    return this.hasRole(BORoles.FLEET_MANAGER);
  }

  hasRole(role) {
    const { roles } = this.getUserInfo();
    return roles ? roles.includes(role) : false;
  }

  getCurrentUserRoles() {
    const { roles } = this.getUserInfo();
    return roles ? roles : [];
  }

  isAuthenticated = () => {
    const token = this.getAccessToken();
    return !!token && this.isTokenFormatValid(token);
  };

  getUsernameFromToken = (token) => {
    try {
      const { sub } = decode(token);
      return sub;
    } catch (error) {
      console.error('Formato de token inválido: subject', error);
      return false;
    }
  };

  saveAuthToken(authToken) {
    try {
      storage.setItem(AUTH_TOKEN, JSON.stringify(authToken));
    } catch (e) {
      return false;
    }
  }

  getCompanyIdFromToken = (token) => {
    try {
      const { idcompany } = decode(token);
      return idcompany;
    } catch (error) {
      console.error('Formato de token inválido: subject', error);
      return false;
    }
  };

  isTokenFormatValid = (token) => {
    return this.getUsernameFromToken(token) === false ? false : true;
  };

  getAuthToken() {
    try {
      const authToken = JSON.parse(storage.getItem(AUTH_TOKEN));
      return authToken ? authToken : '';
    } catch (e) {
      return false;
    }
  }

  getAuthHeader() {
    const typeToken = this.getTypeToken();
    const accessToken = this.getAccessToken();
    if (typeToken && accessToken) {
      return {
        headers: {
          Accept: 'application/json',
          Authorization: typeToken + ' ' + accessToken,
        },
      };
    }
    return null;
  }

  getAuthHeaderBlob() {
    const typeToken = this.getTypeToken();
    const accessToken = this.getAccessToken();
    if (typeToken && accessToken) {
      return {
        responseType: 'blob',
        headers: {
          Accept: 'application/json',
          Authorization: typeToken + ' ' + accessToken,
        },
      };
    }
    return null;
  }

  getAuthHeaderMultipart() {
    const typeToken = this.getTypeToken();
    const accessToken = this.getAccessToken();
    if (typeToken && accessToken) {
      return {
        headers: {
          Accept: 'application/json',
          Authorization: typeToken + ' ' + accessToken,
          'content-type': 'multipart/form-data',
        },
      };
    }
    return null;
  }

  getAuthorizationHeader() {
    return this.getAuthHeader().headers['Authorization'];
  }

  getAccessToken() {
    return this.getAuthToken() && this.getAuthToken().access_token
      ? this.getAuthToken().access_token
      : null;
  }
  getTypeToken() {
    return this.getAuthToken() && this.getAuthToken().token_type
      ? this.getAuthToken().token_type
      : null;
  }

  refreshToken() {
    const token = this.getAuthToken();
    if (token === null) {
      return Promise.reject('no hay token pa refrescar');
    }
    return axiosInstanceWithInterceptors.post(apiRoute.refreshAuthToken, token);
  }

  logout() {
    try {
      storage.removeItem(AUTH_TOKEN);
      storage.removeItem(AUTH_USER);
    } catch (e) {
      return false;
    }
  }

  forceLogout(router) {
    this.logout();
    router.replace(routeLayout.login);
  }

  getUserData() {
    const userInfo = this.getUserInfo();
    let userData = userInfo === '' ? {} : userInfo;
    userData.isLoggedIn = this.isAuthenticated();
    return userData;
  }

  isLoggedUser = (idUser) => {
    return idUser === String(this.getUserId());
  };
}

export default new AuthService();
