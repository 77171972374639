export const Backoffice_Rules = {
  Actions: {
    ADMIN: 'admin',
    ACCESS: 'access',
  },
  Subjects: {
    BACKOFFICE: 'backoffice',
    APPLICATION: 'application',
  },
};
