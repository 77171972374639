export const PERMISSION_KEYS = {
  APPLICATION_ACCESS: 'application:access',
  BACKOFFICE_ACCESS: 'backoffice:access',
  USER_ACTIVATE: 'user:activate',
  USER_CANNOT_EDIT_SUPERADMIN: 'user:cannot-edit-super-admin',
  USER_CANNOT_MODIFY_HIMSELF: 'user:cannot-modify-himself',
  USER_CREATE: 'user:create',
  USER_CURRENT: 'user:current',
  USER_DELETE: 'user:delete',
  USER_EDIT: 'user:edit',
  USER_GET: 'user:get',
  USER_GET_EXCEL: 'user:get_excel',
  USER_LIST: 'user:list',
  DEVICES_ASSIGN: 'devices:assign',
  DEVICES_CREATE: 'devices:create',
  DEVICES_EDIT: 'devices:edit',
  DEVICES_LIST: 'devices:list',
  DEVICES_LIST_OWN_COMPANY: 'devices:list-own-company',
  DEVICES_GET: 'devices:get',
  DEVICES_GET_OWN_COMPANY: 'devices:get-own-company',
  DEVICES_GET_EXCEL: 'devices:get_excel',
  ASSET_CREATE: 'asset:create',
  ASSET_EDIT: 'asset:edit',
  ASSET_GET: 'asset:get',
  ASSET_GET_EXCEL: 'asset:get_excel',
  ASSET_LIST: 'asset:list',
  ASSET_POST_EXCEL: 'asset:post_excel',
  ASSET_UNASSIGN_DEVICE: 'asset:unassign-device',
  FLEET_CREATE: 'fleet:create',
  FLEET_DELETE: 'fleet:delete',
  FLEET_EDIT: 'fleet:edit',
  FLEET_LIST: 'fleet:list',
  FLEET_GET: 'fleet:get',
  FLEET_GET_EXCEL: 'fleet:get_excel',
  NOVELTIES_LIST: 'novelties:list',
  COMPANY_LIST: 'company:list',
  COMPANY_GET: 'company:get',
  ROLES_LIST: 'roles:list',
  ROLES_SET: 'roles:set',
  TAG_CREATE: 'tag:create',
  TAG_LIST: 'tag:list',
};

export const ErrorCodes = {
  TOKEN_EXPIRED: 'token-expired',
  TOKEN_INVALID: 'token-invalid',
  NETWORK_ERROR: 'network-error',
  LOGOUT: 'logout',
};

export const localStorageKeys = {
  AUTH_TOKEN: 'seg_act_/auth_token',
  AUTH_USER: 'seg_act_/user_info',
};

export const SpecialsRole = {
  SYSTEM: 'sistemas',
};

export const BORoles = {
  SUPER_ADMIN: 'super_admin',
  ADMIN_MANAGER: 'manager',
  FLEET_MANAGER: 'fleet_manager',
};

export const CommonRoles = {
  REGULAR: 'regular',
};

export const AuthMode = {
  LDAP: 'ldap',
};

export const COMPANY = {
  ARSAT: 'arsat',
};

export const COMPANY_ID = {
  arsat: 1000,
};

export const DEVICE = {
  AT205: 'AT-205',
};

export const ASSET = {
  VEHICLE: 'vehicle',
};

export const iconSize = {
  sidebar: 24,
  tables: 24,
  main: 36,
  accordion: 32,
  logo: 40,
  input: 32,
  small_icon: 14,
  medium_icon: 21,
  big_icon: 32,
};

export const BORowsPerPageOptions = [10, 20, 50, 100];

export const QueryKeys = {
  STATUS_CONTROL: 'status_control',
  USERS: 'users',
  USERS_FOR_FILTER: 'users_for_filter',
  USERS_AUTOCOMPLETE: 'users_autocomplete',
  USERS_EXPORT: 'users_export',
  DEVICES: 'devices',
  DEVICE: 'device',
  DEVICES_FOR_FILTER: 'devices_for_filter',
  DEVICES_AUTOCOMPLETE: 'devices_autocomplete',
  DEVICES_EXPORT: 'devices_export',
  NOVELTIES: 'novelties',
  NOVELTIES_FOR_FILTER: 'novelties_for_filter',
  NOVELTIES_BY_ASSET: 'novelties_by_asset',
  NOVELTIES_HISTORIC: 'novelties_historic',
  NOVELTIES_DOWNLOAD_CSV: 'novelties_download_CSV',
  NOVELTIES_DOWNLOAD_KMZ: 'novelties_download_KMZ',
  ASSETS: 'assets',
  ASSETS_FOR_FILTER: 'assets_for_filter',
  ASSETS_AUTOCOMPLETE: 'assets_autocomplete',
  ASSETS_TAGS_AUTOCOMPLETE: 'assets_tags_autocomplete',
  ASSETS_MASSIVE_EXPORT: 'assets_massive_export',
  ASSETS_EXPORT: 'assets_export',
  FLEETS: 'fleets',
  FLEETS_AUTOCOMPLETE: 'fleets_autocomplete',
  FLEETS_EXPORT: 'fleets_export',
};

export const LOGIN_ERRORS = {
  'login-user-not-exists':
    'Tu perfil de usuario no existe o no se encuentra activo en el sitio.',
  'login-wrong-password': 'Contraseña incorrecta',
  'login-email-not-confirmed':
    'Para completar el registro es necesario que nos confirmes tu correo electrónico.',
  'login-expired-token':
    'El enlace de verificación expiró. Por favor, solicitá uno nuevo.',
  'login-bad-token':
    'El enlace de verificación es inválido. Por favor, solicitá uno nuevo.',
};

export const ASSET_ERRORS = {
  'register-asset-already-exists':
    'El nombre del activo ya se encuentra en uso.',
  'asset-not-exists': 'El activo no existe.',
  'device-not-available': 'ID del dispositivo asociado no disponible',
};

export const FLEET_ERRORS = {
  'register-fleet-already-exists':
    'El nombre de la flota ya se encuentra en uso.',
  'fleet-not-exists': 'La flota no existe.',
};

export const FLEET_DELETE_ERRORS = {
  'not-enough-permissions': 'No posee permisos para eliminar la flota',
};

export const COMMON_MESSAGES = {
  error: 'Ocurrió un error',
  success: 'Guardado con éxito',
};

export const TILE = {
  attribution:
    '&copy; <a href="https://www.ign.gob.ar/" target="_blank">Instituto Geográfico Nacional</a>',
  url: 'https://wms.ign.gob.ar/geoserver/gwc/service/tms/1.0.0/capabaseargenmap@EPSG%3A3857@png/{z}/{x}/{-y}.png',
};
export const CENTER = { lat: -34.519168, lng: -58.519773 };
export const ZOOM_GLOBAL = 4;
export const ZOOM = 12;
export const MAP_STYLE = { width: '100%', height: 'calc(100vh - 98px)' };

export const ASSET_STATE_OPTIONS = ['moving', 'stopped', 'disconnected'];

export const MAP_ICON = {
  SIZE: {
    NORMAL: [34, 45],
    SMALL: [24, 30],
  },
  ANCHOR: {
    NORMAL: [17, 45],
    SMALL: [12, 15],
  },
  POP_UP_ANCHOR: {
    NORMAL: [0, -45],
    SMALL: [0, -8],
  },
};

export const DATE_BUTTONS = [
  { id_button: "15'", minutes: 15 },
  { id_button: "30'", minutes: 30 },
  { id_button: '1h', minutes: 60 },
  { id_button: '2h', minutes: 120 },
];

export const roleFilterOptions = [
  'Super Administrador',
  'Administrador',
  'Responsable de Flota',
  'Usuario Regular',
];

export const stateOptions = ['Activo', 'Inactivo'];

export const organizationOptions = ['Arsat'];

export const deviceModelOptions = ['AT-205'];

export const failedImportReason = {
  insert_asset_failed: 'Fallo no detallado',
  'device-not-available': 'Dispositivo en uso',
  'asset-name-not-available': 'Nombre del activo en uso',
};

export const statusMeaning = {
  100: 'Continue',
  101: 'Switching protocols',
  102: 'Processing',
  103: 'Early Hints',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi-Status',
  208: 'Already Reported',
  226: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found (Previously "Moved Temporarily")',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'Switch Proxy',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  418: 'I"m a Teapot',
  421: 'Misdirected Request',
  422: 'Unprocessable Entity',
  423: 'Locked',
  424: 'Failed Dependency',
  425: 'Too Early',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required',
};

export const TABLE_COLUMNS = {
  USERS: {
    EMAIL: 'Email',
    FULLNAME: 'Nombre y Apellido',
    ROLE: 'Rol',
    COMPANY: 'Organización',
    STATE: 'Estado',
    AUTHENTICATION: 'Autenticación',
    EDIT: '',
  },
  DEVICES: {
    ID: 'ID',
    MODEL: 'Modelo - Versión',
    COMPANY: 'Organización',
    STATE: 'Estado',
    ASSET_RELATED: 'Activo asociado',
    EDIT: '',
  },
  ASSETS: {
    ID: 'ID Activo',
    NAME: 'Nombre del Activo',
    FLEETS: 'Flotas',
    TAGS: 'Etiquetas',
    ASSIGNED_USERS: 'Usuarios designados',
    LAST_CONNECTION: 'Últ. conexión',
    STATE: 'Estado',
    MAP: 'Mapa',
    EDIT: '',
  },
  FLEETS: {
    NAME: 'Nombre de la Flota',
    RESPONSABLE: 'Responsable',
    ASSETS: 'Activos asociados',
    DETAILS: 'Detalles',
    MAP: 'Mapa',
    BLANK: '',
    EDIT: '',
    DELETE: '',
  },
};

export const MAX_BLANK_ROWS = 10;
export const ROWS_PER_PAGE_MOBILE = 10;

export const SCREEN_SIZE = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};
