import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    primary: ['Encode Sans', 'Arial', 'sans-serif'].join(','),
    secondary: ['Roboto', 'Arial', 'sans-serif'].join(','),
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#0073a5',
      light: '#034c8c',
      dark: '#023059',
      contrastText: '#fff',
    },
    secondary: {
      main: '#262626',
    },
    error: {
      main: '#ff0000',
    },
    success: {
      main: '#84b903',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      disabled: '#8d8d8d',
    },
    grey: {
      100: '#f1f1f1',
      300: '#f2f6f8',
      500: '#f7f7f7',
      700: '#3c5662',
      900: '#303638',
    },
  },
  shape: {
    classic: {
      borderRadius: '5px',
      border: '1px solid #EBEBEB',
    },
  },
  shadows: {
    0: 'none',
    1: '0px 3px 14px rgba(0,0,0,0.12)',
    2: '4px 0px 5px rgba(0,0,0,0.14), 1px 0px 10px rgba(0,0,0,0.12), 2px 0px 4px rgba(0,0,0,0.2)',
    3: '0px 4px 5px rgba(0,0,0,0.14), 0px 1px 10px rgba(0,0,0,0.12), 0px 2px 4px rgba(0,0,0,0.2)',
    6: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rbga(0,0,0,0.12)',
    8: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    24: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
    25: 'inset 0px 4px 5px rgba(0, 0, 0, 0.35), 0px 1px 10px rgba(0, 0, 0, 0.30), 0px 2px 4px rgba(0, 0, 0, 0.5)',
    26: 'inset 0px 4px 5px rgba(0, 0, 0, 0.35)',
  },
  transitions: {
    easing: {
      ease: 'cubic-bezier(0.25, 0.1, 0.25, 1.0)',
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      short: 200,
      standard: 400,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'primary',
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 50,
          fontFamily: ['Roboto', 'Arial', 'sans-serif'].join(','),
          fontWeight: 600,
          height: 36,
          textTransform: 'unset',
          transition: '0.4s all',
          transitionTimingFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1.0)',
          '&.Mui-disabled': {
            backgroundColor: '#8D8D8D',
            color: '#fff',
          },
        },
      },
    },
    MuiInput: {
      defaultProps: {
        autoComplete: 'off',
      },
      styleOverrides: {
        root: {
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '3.5px',
          paddingLeft: '16px',
          paddingRight: '16px',
          textDecoration: 'none',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
  },
});

export default theme;
