export const routeLayout = {
  home: '/',
  login: '/login',
  admin(idcompany) {
    return {
      href: '/[idcompany]/administracion',
      as: `/${idcompany}/administracion`,
      title: 'Administración',
    };
  },
  application(idcompany) {
    return {
      href: '/[idcompany]/aplicativo',
      as: `/${idcompany}/aplicativo`,
      title: 'Aplicativo',
    };
  },
};

export const route = {
  error404: '/404',
  organizations(idcompany) {
    return {
      href: '/[idcompany]/administracion/organizaciones',
      as: `/${idcompany}/administracion/organizaciones`,
      title: 'Organizaciones',
    };
  },
  users(idcompany) {
    return {
      href: '/[idcompany]/administracion/usuarios',
      as: `/${idcompany}/administracion/usuarios`,
      title: 'Usuarios',
    };
  },
  devices(idcompany) {
    return {
      href: '/[idcompany]/administracion/dispositivos',
      as: `/${idcompany}/administracion/dispositivos`,
      title: 'Dispositivos',
    };
  },
  map(idcompany) {
    return {
      href: '/[idcompany]/aplicativo/mapa',
      as: `/${idcompany}/aplicativo/mapa`,
      title: 'Mapa',
    };
  },
  mapByAssetId(idcompany, idasset) {
    return {
      href: '/[idcompany]/aplicativo/mapa/[idasset]',
      as: `/${idcompany}/aplicativo/mapa/${idasset}`,
      title: 'Mapa por id del activo',
    };
  },
  assets(idcompany) {
    return {
      href: '/[idcompany]/aplicativo/activos',
      as: `/${idcompany}/aplicativo/activos`,
      title: 'Activos',
    };
  },
  alarms(idcompany) {
    return {
      href: '/[idcompany]/aplicativo/alarmas',
      as: `/${idcompany}/aplicativo/alarmas`,
      title: 'Alarmas',
    };
  },
  fleets(idcompany) {
    return {
      href: '/[idcompany]/aplicativo/flotas',
      as: `/${idcompany}/aplicativo/flotas`,
      title: 'Flotas',
    };
  },
};

export const apiRoute = {
  status_control: '/auth/all/',
  authToken: '/auth/token',
  forgotAuthPassword: '/auth/forgot_password',
  refreshAuthToken: '/auth/refresh_token',
  currentUser: '/users/current/',
  users: '/users',
  users_autocomplete: '/users/autocomplete/short?active=true',
  users_export_user_list: '/users/export/user_list',
  devices: '/device',
  devices_autocomplete: '/device/autocomplete/short',
  devices_export_device_list: '/device/export/device_list',
  novelties: '/novelties/',
  novelties_historic: '/novelties/historic-summary/',
  novelties_export_csv: '/novelties/export-csv/',
  novelties_export_kmz: '/novelties/export-kmz/',
  assets: '/asset/assets/',
  assets_export_asset_list: '/asset/export/asset_list',
  assets_export_massive_post: '/asset/export/massive_post',
  assets_verify_import: '/asset/export/verify_import_excel',
  assets_autocomplete: '/asset/assets/autocomplete/short',
  assets_tags: '/asset/tags/',
  assets_unassign_device: '/asset/assets/unassign/',
  assets_autocomplete_tags: '/asset/tags/autocomplete/short',
  fleets: '/asset/fleets/',
  fleets_delete: '/asset/fleets/delete/',
  fleets_autocomplete: '/asset/fleets/autocomplete/short',
  fleets_export_fleet_list: '/asset/export/fleet_list',
};
