//https://sergiodxa.com/articles/feature-flags-react
import { createContext, useContext } from 'react';

const FeaturesContext = createContext();

export const FeaturesProvider = FeaturesContext.Provider;

export const Feature = ({ name, children }) => {
  const flags = useContext(FeaturesContext);

  if (!children) {
    return null;
  }
  return flags[name] ? children : null;
};

// Custom Hooks
export function useFeatures() {
  return useContext(FeaturesContext);
}
export function useFeature(name) {
  const features = useFeatures();
  if (Array.isArray(features)) return features.includes(name);
  if (typeof features[name] === 'boolean') return features[name];
}
