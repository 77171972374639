import { Ability, AbilityBuilder, defineAbility } from '@casl/ability';
import AuthService from 'src/services/AuthService';
import { BORoles, CommonRoles } from 'src/utils/Constants';
import { Backoffice_Rules } from 'src/context/ability/Rules';

export const setRules = (can, cannot) => {
  //metodo donde se define las acciones por rol
  //tener en cuenta que cuando haya multiples rols por usuarios dejar el rol mas grande ultimo
  const userRoles = AuthService.getCurrentUserRoles();

  if (userRoles.includes(CommonRoles.REGULAR)) {
    cannot(
      Backoffice_Rules.Actions.ACCESS,
      Backoffice_Rules.Subjects.BACKOFFICE
    );
    cannot(
      Backoffice_Rules.Actions.ADMIN,
      Backoffice_Rules.Subjects.BACKOFFICE
    );
    can(Backoffice_Rules.Actions.ADMIN, Backoffice_Rules.Subjects.APPLICATION);
  }

  if (userRoles.includes(BORoles.FLEET_MANAGER)) {
    cannot(
      Backoffice_Rules.Actions.ACCESS,
      Backoffice_Rules.Subjects.BACKOFFICE
    );
    cannot(
      Backoffice_Rules.Actions.ADMIN,
      Backoffice_Rules.Subjects.BACKOFFICE
    );
    can(Backoffice_Rules.Actions.ADMIN, Backoffice_Rules.Subjects.APPLICATION);
  }

  if (userRoles.includes(BORoles.ADMIN_MANAGER)) {
    cannot(
      Backoffice_Rules.Actions.ADMIN,
      Backoffice_Rules.Subjects.BACKOFFICE
    );
    can(Backoffice_Rules.Actions.ADMIN, Backoffice_Rules.Subjects.APPLICATION);
  }

  if (userRoles.includes(BORoles.SUPER_ADMIN)) {
    can(Backoffice_Rules.Actions.ADMIN, Backoffice_Rules.Subjects.BACKOFFICE);
    can(Backoffice_Rules.Actions.ADMIN, Backoffice_Rules.Subjects.APPLICATION);
  }
};

const ability = defineAbility((can, cannot) => {
  //metodo principal donde se settean y definen las reglas. las reglas (rules) son todos los can y cannot definidos
  setRules(can, cannot);
});

export default ability;
export const updateAbility = () => {
  //metodo para actualizar las rules al realizarse un login o logoff
  const { can, cannot, rules } = new AbilityBuilder(Ability);
  setRules(can, cannot);
  ability.update(rules);
};
